import {useLocation} from "react-router-dom";

function useQueryParam(paramName) {
    const {search} = useLocation();

    // Используем URLSearchParams для анализа строки запроса
    const params = new URLSearchParams(search);
    return params.get(paramName);

}

export default useQueryParam;